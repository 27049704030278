import Icon from '@/components/Icon'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import * as directives from '@/directives'
import { App, Directive } from 'vue'

const install = (app: App) => {
  for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }
  Object.keys(directives).forEach((key) => {
    app.directive(key, (directives as { [key: string]: Directive })[key])
  })
  app.component('Icon', Icon)
}

export default install
