import Layout from '@/layout/index.vue'
import { $t } from '@/plugins/i18n'

const systemRouter = {
  path: '/config',
  name: 'config',
  component: Layout,
  redirect: '/config/mall',
  meta: {
    icon: 'Tools',
    title: $t('menus.hsconfig'),
    i18n: true,
    rank: 2,
  },
  children: [
    {
      path: '/config/mall',
      name: 'configMall',
      component: () => import('@/views/config/mall.vue'),
      meta: {
        title: $t('menus.hsconfig-mall'),
        i18n: true,
        icon: 'Key',
        rank: 201,
        isHidden: false,
        keepAlive: true,
      },
    },
    {
      path: '/config/message',
      name: 'configMessage',
      component: () => import('@/views/config/message.vue'),
      meta: {
        title: $t('menus.hsconfig-message'),
        i18n: true,
        icon: 'Key',
        rank: 202,
        isHidden: false,
        keepAlive: true,
      },
    },
    {
      path: '/config/weixin',
      name: 'configWeixin',
      component: () => import('@/views/config/weixin.vue'),
      meta: {
        title: $t('menus.hsconfig-weixin'),
        i18n: true,
        icon: 'Key',
        rank: 203,
        isHidden: false,
        keepAlive: true,
      },
    },
    {
      path: '/config/customer',
      name: 'configCustomer',
      component: () => import('@/views/config/customer.vue'),
      meta: {
        title: $t('menus.hsconfig-customer'),
        i18n: true,
        icon: 'Key',
        rank: 204,
        isHidden: false,
        keepAlive: true,
      },
    },
    {
      path: '/config/order',
      name: 'configOrder',
      component: () => import('@/views/config/order.vue'),
      meta: {
        title: $t('menus.hsconfig-order'),
        i18n: true,
        icon: 'Key',
        rank: 205,
        isHidden: false,
        keepAlive: true,
      },
    },
  ],
}

export default systemRouter
