<script setup lang="ts">
import {
  ref,
  watch,
  unref,
  reactive,
  nextTick,
  computed,
  ComputedRef,
  CSSProperties,
  onBeforeMount,
  getCurrentInstance,
  shallowRef,
  onBeforeUnmount,
} from 'vue'

import { SvgIcon } from '@/components/Icon'

import { emitter } from '@/utils/mitt'
import { $t as t, transformI18n } from '@/plugins/i18n'
import { storageLocal } from '@/utils/storage'
import { RouteLocationNormalizedLoaded, RouteMeta, useRoute, useRouter } from 'vue-router'
import { RouteConfigs, routerArrays, tagsViewsType } from '../../types'
import { useSettingStoreHook } from '@/store/modules/settings'
import { handleAliveRoute, delAliveRoutes } from '@/router/utils'
import { useMultiTagsStoreHook } from '@/store/modules/multiTags'
import { usePermissionStoreHook } from '@/store/modules/permission'
import { toggleClass, removeClass, hasClass } from '@/utils/operate'
import { templateRef, useResizeObserver, useDebounceFn } from '@vueuse/core'
import { multiType } from '@/store/modules/types'

const route = useRoute()
const router = useRouter()
const translateX = ref<number>(0)
const activeIndex = ref<number>(-1)
let refreshButton = 'refresh-button'
const instance = getCurrentInstance()
const pureSetting = useSettingStoreHook()
const tabDom = templateRef<HTMLElement | null>('tabDom', null)
const containerDom = ref()
const scrollbarDom = ref()
const showTags = ref(storageLocal.getItem('rs-configure').hideTabs) ?? 'false'
let multiTags: ComputedRef<Array<multiType>> = computed(() => {
  return useMultiTagsStoreHook()?.multiTags
})

const linkIsActive = computed(() => {
  return (item: { path: string; fullPath: string }) => {
    if (route.fullPath === item.fullPath) {
      return 'is-active'
    } else {
      return ''
    }
  }
})

const scheduleIsActive = computed(() => {
  return (item: { path: string; fullPath: string }) => {
    if (route.fullPath === item.fullPath) {
      return 'schedule-active'
    } else {
      return ''
    }
  }
})

const iconIsActive = computed(() => {
  return (item: { path: string; fullPath: string }, index: number) => {
    if (index === 0) return
    if (route.fullPath === item.fullPath) {
      return true
    } else {
      return false
    }
  }
})

const dynamicTagView = () => {
  const index = multiTags.value.findIndex((item) => {
    return item.path === route.path
  })
  moveToView(index)
}

useResizeObserver(
  scrollbarDom,
  useDebounceFn(() => {
    dynamicTagView()
  }, 200)
)

const tabNavPadding = 10
const moveToView = (index: number): void => {
  if (!instance?.refs['dynamic' + index]) {
    return
  }
  const tabItemEl = (instance?.refs['dynamic' + index] as any)[0]
  const tabItemElOffsetLeft = (tabItemEl as HTMLElement)?.offsetLeft
  const tabItemOffsetWidth = (tabItemEl as HTMLElement)?.offsetWidth
  // 标签页导航栏可视长度（不包含溢出部分）
  const scrollbarDomWidth = scrollbarDom.value ? scrollbarDom.value?.offsetWidth : 0
  // 已有标签页总长度（包含溢出部分）
  const tabDomWidth = tabDom.value ? tabDom.value?.offsetWidth : 0

  if (tabDomWidth < scrollbarDomWidth || tabItemElOffsetLeft === 0) {
    translateX.value = 0
  } else if (tabItemElOffsetLeft < -translateX.value) {
    // 标签在可视区域左侧
    translateX.value = -tabItemElOffsetLeft + tabNavPadding
  } else if (
    tabItemElOffsetLeft > -translateX.value &&
    tabItemElOffsetLeft + tabItemOffsetWidth < -translateX.value + scrollbarDomWidth
  ) {
    // 标签在可视区域
    translateX.value = Math.min(
      0,
      scrollbarDomWidth - tabItemOffsetWidth - tabItemElOffsetLeft - tabNavPadding
    )
  } else {
    // 标签在可视区域右侧
    translateX.value = -(
      tabItemElOffsetLeft -
      (scrollbarDomWidth - tabNavPadding - tabItemOffsetWidth)
    )
  }
}

const handleScroll = (offset: number): void => {
  const scrollbarDomWidth = scrollbarDom.value ? scrollbarDom.value?.offsetWidth : 0
  const tabDomWidth = tabDom.value ? tabDom.value.offsetWidth : 0
  if (offset > 0) {
    translateX.value = Math.min(0, translateX.value + offset)
  } else {
    if (scrollbarDomWidth < tabDomWidth) {
      if (translateX.value >= -(tabDomWidth - scrollbarDomWidth)) {
        translateX.value = Math.max(translateX.value + offset, scrollbarDomWidth - tabDomWidth)
      }
    } else {
      translateX.value = 0
    }
  }
}

const tagsViews = reactive<Array<tagsViewsType>>([
  {
    icon: 'refresh',
    text: t('buttons.hsreload'),
    divided: false,
    disabled: false,
    show: true,
  },
  {
    icon: 'close',
    text: t('buttons.hscloseCurrentTab'),
    divided: false,
    disabled: multiTags.value.length > 1 ? false : true,
    show: true,
  },
  {
    icon: 'close-left',
    text: t('buttons.hscloseLeftTabs'),
    divided: true,
    disabled: multiTags.value.length > 1 ? false : true,
    show: true,
  },
  {
    icon: 'close-right',
    text: t('buttons.hscloseRightTabs'),
    divided: false,
    disabled: multiTags.value.length > 1 ? false : true,
    show: true,
  },
  {
    icon: 'close-other',
    text: t('buttons.hscloseOtherTabs'),
    divided: true,
    disabled: multiTags.value.length > 2 ? false : true,
    show: true,
  },
  {
    icon: 'close-all',
    text: t('buttons.hscloseAllTabs'),
    divided: false,
    disabled: multiTags.value.length > 1 ? false : true,
    show: true,
  },
])

// 显示模式，默认灵动模式显示
const showModel = ref(storageLocal.getItem('rs-configure')?.showModel || 'smart')
if (!showModel.value) {
  const configure = storageLocal.getItem('rs-configure')
  configure.showModel = 'card'
  storageLocal.setItem('rs-configure', configure)
}

let visible = ref(false)
let buttonLeft = ref(0)
let buttonTop = ref(0)

// 当前右键选中的路由信息
let currentSelect = ref({})

function dynamicRouteTag(route: RouteLocationNormalizedLoaded): void {
  console.log('route.fullPath: ', route.fullPath)
  const currentIndex = multiTags.value.findIndex((item) => {
    return item.fullPath === route.fullPath
  })

  if (currentIndex === -1 && route.name !== 'redirect') {
    useMultiTagsStoreHook().handleTags('push')({
      path: route.path,
      parentPath: '/',
      meta: route.meta,
      name: route.name,
      fullPath: route.fullPath,
    })
    setTimeout(() => {
      showMenuModel(route.path)
    })
  } else {
    moveToView(currentIndex)
  }
}

// 重新加载
function onFresh() {
  toggleClass(true, refreshButton, document.querySelector('.rotate'))
  const { fullPath } = unref(route)
  router.replace('/redirect' + fullPath)
  setTimeout(() => {
    removeClass(document.querySelector('.rotate'), refreshButton)
  }, 600)
}

function deleteDynamicTag(obj: any, current: any, tag?: string) {
  // 存放被删除的缓存路由
  let delAliveRouteList: RouteConfigs[] = []
  let valueIndex: number = multiTags.value.findIndex((item: any) => {
    return item.fullPath === obj.fullPath
  })

  const spliceRoute = (startIndex?: number, length?: number, other?: boolean): void => {
    if (other) {
      useMultiTagsStoreHook().handleTags('equal')([...routerArrays, obj])
    } else {
      // @ts-ignore
      delAliveRouteList = useMultiTagsStoreHook().handleTags('splice')('', {
        startIndex,
        length,
      })
    }
  }

  if (tag === 'other') {
    spliceRoute(1, 1, true)
  } else if (tag === 'left') {
    spliceRoute(1, valueIndex - 1)
  } else if (tag === 'right') {
    spliceRoute(valueIndex + 1, multiTags.value.length)
  } else {
    // 从当前匹配到的路径中删除
    spliceRoute(valueIndex, 1)
  }
  let newRoute = useMultiTagsStoreHook().handleTags('slice')()
  if (current === route.path) {
    // 删除缓存路由
    tag ? delAliveRoutes(delAliveRouteList) : handleAliveRoute(route.matched, 'delete')
    // 如果删除当前激活tag就自动切换到最后一个tag
    if (tag === 'left') return
    nextTick(() => {
      router.push(newRoute[0].fullPath)
    })
  } else {
    // 删除缓存路由
    tag ? delAliveRoutes(delAliveRouteList) : delAliveRoutes([obj])
    if (!multiTags.value.length) return
    let isHasActiveTag = multiTags.value.some((item) => {
      return item.path === route.path
    })
    !isHasActiveTag && router.push(newRoute[0].fullPath)
  }
}

function deleteMenu(
  item:
    | {
        path?: string
        fullPath?: string
        meta?: {
          title?: string
          i18n?: boolean
          icon?: string
          isHidden?: boolean
          savedPosition?: boolean
          authority?: string[]
        }
        name?: string
        query?: object
      }
    | { path: string; meta: RouteMeta; name?: undefined; query?: undefined; fullPath?: string },
  tag?: string
) {
  deleteDynamicTag(item, item.path, tag)
}

function onClickDrop(key: any, item: { disabled: any }, selectRoute?: RouteConfigs) {
  if (item && item.disabled) return

  let selectTagRoute
  if (selectRoute) {
    selectTagRoute = {
      path: selectRoute.path,
      meta: selectRoute.meta,
      name: selectRoute.name,
      fullPath: selectRoute.fullPath,
    }
  } else {
    selectTagRoute = { path: route.path, meta: route.meta, fullPath: route.fullPath }
  }

  // 当前路由信息
  switch (key) {
    case 0:
      // 重新加载
      onFresh()
      break
    case 1:
      // 关闭当前标签页
      deleteMenu(selectTagRoute)
      break
    case 2:
      // 关闭左侧标签页
      deleteMenu(selectTagRoute, 'left')
      break
    case 3:
      // 关闭右侧标签页
      deleteMenu(selectTagRoute, 'right')
      break
    case 4:
      // 关闭其他标签页
      deleteMenu(selectTagRoute, 'other')
      break
    case 5:
      // 关闭全部标签页
      useMultiTagsStoreHook().handleTags('splice')('', {
        startIndex: 1,
        length: multiTags.value.length,
      })
      usePermissionStoreHook().clearAllCachePage()
      router.push('/dashboard')
      break
  }
  setTimeout(() => {
    showMenuModel(route.path)
  })
}

// 触发右键中菜单的点击事件
function selectTag(key: any, item: any) {
  onClickDrop(key, item, currentSelect.value)
}

function closeMenu() {
  visible.value = false
}

function showMenus(value: boolean) {
  Array.of(1, 2, 3, 4, 5).forEach((v) => {
    tagsViews[v].show = value
  })
}

function disabledMenus(value: boolean) {
  Array.of(1, 2, 3, 4, 5).forEach((v) => {
    tagsViews[v].disabled = value
  })
}

// 检查当前右键的菜单两边是否存在别的菜单，如果左侧的菜单是首页，则不显示关闭左侧标签页，如果右侧没有菜单，则不显示关闭右侧标签页
function showMenuModel(currentPath: string, refresh = false) {
  let allRoute = multiTags.value
  let routeLength = multiTags.value.length
  let currentIndex = -1
  currentIndex = allRoute.findIndex((v) => v.path === currentPath)

  showMenus(true)

  if (refresh) {
    tagsViews[0].show = true
  }

  /**
   * currentIndex为1时，左侧的菜单是首页，则不显示关闭左侧标签页
   * 如果currentIndex等于routeLength-1，右侧没有菜单，则不显示关闭右侧标签页
   */
  if (currentIndex === 1 && routeLength !== 2) {
    // 左侧的菜单是首页，右侧存在别的菜单
    tagsViews[2].show = false
    Array.of(1, 3, 4, 5).forEach((v) => {
      tagsViews[v].disabled = false
    })
    tagsViews[2].disabled = true
  } else if (currentIndex === 1 && routeLength === 2) {
    disabledMenus(false)
    // 左侧的菜单是首页，右侧不存在别的菜单
    Array.of(2, 3, 4).forEach((v) => {
      tagsViews[v].show = false
      tagsViews[v].disabled = true
    })
  } else if (routeLength - 1 === currentIndex && currentIndex !== 0) {
    // 当前路由是所有路由中的最后一个
    tagsViews[3].show = false
    Array.of(1, 2, 4, 5).forEach((v) => {
      tagsViews[v].disabled = false
    })
    tagsViews[3].disabled = true
  } else if (currentIndex === 0 || currentPath === '/redirect/dashboard') {
    // 当前路由为首页
    disabledMenus(true)
  } else {
    disabledMenus(false)
  }
}

function openMenu(
  tag: { path: string; fullPath: string },
  e: { clientX: number; clientY: number }
) {
  closeMenu()
  if (tag.path === '/dashboard') {
    // 右键菜单为首页，只显示刷新
    showMenus(false)
    tagsViews[0].show = true
  } else if (multiTags.value.length === 2 && route.path !== tag.path) {
    showMenus(true)
    // 只有两个标签时不显示关闭其他标签页
    tagsViews[4].show = false
  } else if (route.path !== tag.path) {
    // 右键菜单不匹配当前路由，隐藏刷新
    tagsViews[0].show = false
    showMenuModel(tag.path)
  } else if (route.path === tag.path) {
    // 右键当前激活的菜单
    showMenuModel(tag.path, true)
  }

  currentSelect.value = tag
  const menuMinWidth = 105
  const offsetLeft = unref(containerDom).getBoundingClientRect().left
  const offsetWidth = unref(containerDom).offsetWidth
  const maxLeft = offsetWidth - menuMinWidth
  const left = e.clientX - offsetLeft + 5
  if (left > maxLeft) {
    buttonLeft.value = maxLeft
  } else {
    buttonLeft.value = left
  }
  pureSetting.hiddenSideBar ? (buttonTop.value = e.clientY) : (buttonTop.value = e.clientY - 40)
  setTimeout(() => {
    visible.value = true
  }, 10)
}

// 触发tags标签切换
function tagOnClick(item: multiType) {
  router.push(item?.fullPath)
  showMenuModel(item?.path)
}

// 鼠标移入
function onMouseenter(index: number) {
  if (index) activeIndex.value = index
  if (unref(showModel) === 'smart') {
    const scheduleNode = instance?.refs['schedule' + index]
      ? (instance?.refs['schedule' + index] as any)[0]
      : null
    if (hasClass(scheduleNode, 'schedule-active')) return
    toggleClass(true, 'schedule-in', scheduleNode)
    toggleClass(false, 'schedule-out', scheduleNode)
  } else {
    const dynamicNode = instance?.refs['dynamic' + index]
      ? (instance?.refs['dynamic' + index] as any)[0]
      : null
    if (hasClass(dynamicNode, 'card-active')) return
    toggleClass(true, 'card-in', dynamicNode)
    toggleClass(false, 'card-out', dynamicNode)
  }
}

// 鼠标移出
function onMouseleave(index: number) {
  activeIndex.value = -1

  if (unref(showModel) === 'smart') {
    const scheduleNode = instance?.refs['schedule' + index]
      ? (instance?.refs['schedule' + index] as any)[0]
      : null
    if (hasClass(scheduleNode, 'schedule-active')) return
    toggleClass(false, 'schedule-in', scheduleNode)
    toggleClass(true, 'schedule-out', scheduleNode)
  } else {
    const dynamicNode = instance?.refs['dynamic' + index]
      ? (instance?.refs['dynamic' + index] as any)[0]
      : null
    if (hasClass(dynamicNode, 'card-active')) return
    toggleClass(false, 'card-in', dynamicNode)
    toggleClass(true, 'card-out', dynamicNode)
  }
}

watch([route], () => {
  dynamicRouteTag(unref(route))
})

watch(
  () => visible.value,
  (val) => {
    if (val) {
      document.body.addEventListener('click', closeMenu)
    } else {
      document.body.removeEventListener('click', closeMenu)
    }
  }
)

onBeforeMount(() => {
  if (!instance) return

  // 根据当前路由初始化操作标签页的禁用状态
  showMenuModel(route.path)

  // 触发隐藏标签页
  emitter.on('tagViewsChange', (key) => {
    if (unref(showTags) === key) return
    showTags.value = key
  })

  // 改变标签风格
  emitter.on('tagViewsShowModel', (key) => {
    showModel.value = key
  })
})

const getTabStyle = computed((): CSSProperties => {
  return {
    transform: `translateX(${translateX.value}px)`,
  }
})

const getContextMenuStyle = computed((): CSSProperties => {
  return { left: buttonLeft.value + 'px', top: buttonTop.value + 'px' }
})

onBeforeUnmount(() => {
  // 解绑`tagViewsChange`、`tagViewsShowModel`公共事件，防止多次触发
  emitter.off('tagViewsChange')
  emitter.off('tagViewsShowModel')
})
</script>

<template>
  <div v-if="!showTags" ref="containerDom" class="tags-view">
    <div class="arrow-left">
      <icon icon="ep:arrow-left" @click="handleScroll(200)" />
    </div>
    <div ref="scrollbarDom" class="scroll-container">
      <div ref="tabDom" class="tab" :style="getTabStyle">
        <div
          v-for="(item, index) in multiTags"
          :ref="'dynamic' + index"
          :key="index"
          :class="[
            'scroll-item is-closable',
            linkIsActive(item),
            $route.path === item.path && showModel === 'card' ? 'card-active' : '',
          ]"
          @contextmenu.prevent="openMenu(item, $event)"
          @mouseenter.prevent="onMouseenter(index)"
          @mouseleave.prevent="onMouseleave(index)"
          @click="tagOnClick(item)"
        >
          <a>{{ transformI18n(item.meta?.title) }}</a>
          <icon
            v-if="iconIsActive(item, index) || (index === activeIndex && index !== 0)"
            icon="ep:close-bold"
            class="icon-close"
            :size="13"
            @click.stop="deleteMenu(item)"
          >
          </icon>
          <div
            v-if="showModel !== 'card'"
            :ref="'schedule' + index"
            :class="[scheduleIsActive(item)]"
          ></div>
        </div>
      </div>
    </div>
    <span class="arrow-right">
      <icon icon="ep:arrow-right" @click="handleScroll(-200)" />
    </span>
    <!-- 右键菜单按钮 -->
    <transition name="el-zoom-in-top">
      <ul v-show="visible" :key="Math.random()" :style="getContextMenuStyle" class="contextmenu">
        <div v-for="(item, key) in tagsViews" :key="key" style="display: flex; align-items: center">
          <li v-if="item.show" @click="selectTag(key, item)">
            <svg-icon :key="key" :name="item.icon"></svg-icon>
            {{ $t(item.text) }}
          </li>
        </div>
      </ul>
    </transition>
    <!-- 右侧功能按钮 -->
    <ul class="right-button">
      <li>
        <icon
          :title="$t('buttons.hsreload')"
          class="rotate"
          icon="ep:refresh-right"
          @click="onFresh"
        >
        </icon>
      </li>
      <li>
        <el-dropdown trigger="click" placement="bottom-end">
          <icon icon="ep:arrow-down" />
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                v-for="(item, key) in tagsViews"
                :key="key"
                :divided="item.divided"
                :disabled="item.disabled"
                @click="onClickDrop(key, item)"
              >
                <svg-icon :name="item.icon" style="margin-right: 6px"></svg-icon>
                {{ $t(item.text) }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </li>
      <li>
        <slot></slot>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
@import './index.scss';
</style>
