import { toRouteType } from './types'
import NProgress from '@/utils/progress'
import { constantRoutes } from './modules'
import remainingRouter from './modules/remaining'
import { Router, createRouter } from 'vue-router'
import { getHistoryMode, handleAliveRoute } from './utils'
import { getToken } from '@/utils/auth'
import { useUserStoreHook } from '@/store/modules/user'
import { isUrl } from '@/utils/is'
import { openLink } from '@/utils/link'
const whiteList = ['/login']
const userStore = useUserStoreHook()
// 创建路由实例
export const router: Router = createRouter({
  history: getHistoryMode(),
  routes: constantRoutes.concat(...remainingRouter),
  strict: true,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      if (savedPosition) {
        return savedPosition
      } else {
        if (from.meta.saveScrollTop) {
          const top: number = document.documentElement.scrollTop || document.body.scrollTop
          resolve({ left: 0, top })
        }
      }
    })
  },
})

router.beforeEach((to: toRouteType, _from, next) => {
  if (to.meta?.keepAlive) {
    const newMatched = to.matched
    if (to.name !== 'redirect') {
      handleAliveRoute(newMatched, 'add')
    }
    // 页面整体刷新和点击标签页刷新
    if (_from.name === undefined || _from.name === 'redirect') {
      handleAliveRoute(newMatched)
    }
  }
  NProgress.start()
  const externalLink = isUrl(to?.name as string)
  /** 如果已经登录并存在登录信息后不能跳转到路由白名单，而是继续保持在当前页面 */
  function toCorrectRoute() {
    whiteList.includes(to.fullPath) ? next(_from.fullPath) : next()
  }
  try {
    if (getToken()) {
      if (_from?.name) {
        // name为超链接
        if (externalLink) {
          openLink(to?.name as string)
          NProgress.done()
        } else {
          toCorrectRoute()
        }
      } else {
        if (userStore.userInfo) {
          toCorrectRoute()
        } else {
          // token 存在，但是用户信息不存在，则获取（页面刷新时）
          userStore
            .getUserInfo()
            .then((resp) => {
              if (to.matched.length === 0) {
                next(to.fullPath)
              } else {
                toCorrectRoute()
              }
            })
            .catch((e) => {
              throw new Error('获取用户信息异常')
            })
        }
      }
    } else {
      if (to.path !== '/login') {
        if (whiteList.indexOf(to.path) !== -1) {
          next()
        } else {
          next({ path: '/login' })
        }
      } else {
        next()
      }
    }
  } catch (error) {
    //出现异常则退出登录
    useUserStoreHook().logout()
    NProgress.done()
  }
})

router.afterEach(() => {
  NProgress.done()
})

export default router
