<template>
  <el-config-provider :locale="locale" size="small">
    <router-view />
  </el-config-provider>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import dayjs from 'dayjs'
// 引入中文
import cn from 'dayjs/locale/zh-cn'
dayjs.locale({
  ...cn,
  weekStart: 1,
})

export default defineComponent({
  name: 'App',
  setup() {
    return {
      locale: zhCn,
    }
  },
})
</script>
<style lang="scss">
// 导入公共样式
@import './style/index.scss';
@import 'vxe-table/lib/style.css';
@import 'element-plus/dist/index.css';
</style>
