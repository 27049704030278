<template>
  <el-dropdown trigger="click" placement="bottom-end" size="large">
    <div class="dropdown-badge">{{ currentCompany?.name }}</div>
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item
          v-for="item in list"
          :key="item.id"
          class="item"
          @click="handleCompanyChange(item)"
        >
          {{ item.name }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script setup lang="ts">
import { getRouterCodeList, switchRouterCode } from '@/api/auth'
import { companyModel } from '@/model/authModel'
import { useUserStore } from '@/store/modules/user'

const userStore = useUserStore()
const list = ref<companyModel[]>([])
const currentCompany = ref<Nullable<companyModel>>(null)
const getList = () => {
  getRouterCodeList().then((resp) => {
    resp = resp || []
    list.value = resp
    if (!userStore.routerCode && resp.length > 0) {
      currentCompany.value = resp[0] || null
      if (currentCompany.value) {
        handleCompanyChange(currentCompany.value)
      }
    } else {
      currentCompany.value =
        resp.find((item) => {
          return item.code === userStore.routerCode
        }) || null
    }
  })
}

const handleCompanyChange = (item: companyModel) => {
  console.log('item: ', item)
  userStore.setUserRouterCode(item.code)
  switchRouterCode().then((token) => {
    userStore.setToken(token)
    window.location.reload()
  })
}

getList()
</script>

<style lang="scss" scoped>
.dropdown-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 160px;
  cursor: pointer;
}

.item {
  ::v-deep(.el-dropdown-menu__item) {
    min-width: 100%;
    display: inline-flex;
    flex-wrap: wrap;
  }
}
</style>
