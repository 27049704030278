<script setup lang="ts">
const props = defineProps({
  collapse: Boolean,
})

const title = import.meta.env.VITE_GLOB_APP_TITLE
</script>

<template>
  <div class="sidebar-logo-container" :class="{ collapse: props.collapse }">
    <transition name="sidebarLogoFade">
      <router-link key="props.collapse" :title="title" class="sidebar-logo-link" to="/">
        <img src="@/assets/logo.png" alt="" />
        <span class="sidebar-title">{{ title }}</span>
      </router-link>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
@import '@/layout/theme/default-vars.scss';
.sidebar-logo-container {
  position: relative;
  width: 100%;
  height: 48px;
  text-align: center;
  overflow: hidden;

  .sidebar-logo-link {
    height: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-top: 6px;
    .sidebar-title {
      color: $logoTitle;
      font-weight: 600;
      font-size: 20px;
      margin-left: 10px;
      line-height: 36px;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
    }
    img {
      height: 36px;
      width: 36px;
    }
  }

  .collapse {
    .sidebar-title {
      display: none;
    }
  }
}
</style>
