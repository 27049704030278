import { companyModel } from './../model/authModel'
import { http } from '@/utils/http'
import { loginParams } from '../model/authModel'
import { UserModel } from '../model/UserModel'

enum Api {
  Login = '/login',
  GetUserInfo = '/getInfo',
  CheckLogin = '/checkLogin',
  ChangePwd = '/changePwd',
  GetVerificationImage = '/verificationImage',
  GetRouterCodeList = '/routerList',
  SwitchRouterCode = '/switchRouterCode',
}

// 登录
export const login = (params: loginParams) => {
  return http.post<string>({ url: Api.Login, params })
}

/**
 * 获取用户信息
 * @returns
 */
export function getUserInfo() {
  return http.get<UserModel>({ url: Api.GetUserInfo })
}

export function checkLogin(token: string) {
  return http.get<boolean>({ url: Api.CheckLogin, params: { token } })
}

export function changePwd(pwdNew: string, pwdNewConfirm: string, pwdOld: string) {
  return http.put<void>({ url: Api.ChangePwd, data: { pwdNew, pwdNewConfirm, pwdOld } })
}

export function getVerificationImage(codeId?: string) {
  return http.get<{
    codeId: string
    imageBase64Data: string
  }>({
    url: Api.GetVerificationImage,
    params: {
      codeId,
    },
  })
}

export function getRouterCodeList() {
  return http.put<companyModel[]>({ url: Api.GetRouterCodeList })
}

export function switchRouterCode() {
  return http.put<string>({ url: Api.SwitchRouterCode })
}
