import Layout from '@/layout/index.vue'
import { $t } from '@/plugins/i18n'
import { createCustomComponent } from '../utils'

const goodsRouter = {
  path: '/goods',
  name: 'goods',
  component: Layout,
  redirect: '/goods/index',
  meta: {
    icon: 'Burger',
    title: $t('menus.hsgoods'),
    i18n: true,
    rank: 5,
  },
  children: [
    {
      path: '/goods/index',
      name: 'goodsManage',
      component: () => import('@/views/goods/index.vue'),
      meta: {
        title: $t('menus.hsgoods-manage'),
        i18n: true,
        icon: 'Notebook',
        rank: 501,
        isHidden: false,
        keepAlive: true,
      },
    },
    {
      path: '/goods/batch',
      name: 'batch',
      component: () => import('@/views/goods/batch.vue'),
      meta: {
        title: $t('menus.hsgoods-batch'),
        i18n: true,
        icon: 'Upload',
        rank: 501,
        isHidden: false,
        keepAlive: true,
      },
    },
    {
      path: '/goods/input',
      name: 'goodsInput',
      component: () => import('@/views/goods/input.vue'),
      meta: {
        title: $t('menus.hsgoods-input-manage'),
        i18n: true,
        icon: 'Files',
        rank: 502,
        isHidden: false,
        keepAlive: true,
      },
    },
  ],
}

export default goodsRouter
