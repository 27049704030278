import router from './router'
import App from './App.vue'
import usI18n from '../src/plugins/i18n'
import useVxeTable from '../src/plugins/vxeTable'
import useIcon from '../src/plugins/Icon'
import useFormCreate from '../src/plugins/formCreate'
import { createApp } from 'vue'
import { setupStore } from '@/store'
import { getServerConfig } from './config'
import { MotionPlugin } from '@vueuse/motion'
import { injectResponsiveStorage } from '@/utils/storage/responsive'
import 'virtual:windi.css'
// Register icon sprite
import 'virtual:svg-icons-register'

import ElementPlus from 'element-plus/es/index'
import 'element-plus/dist/index.css'

async function bootstrap() {
  const app = createApp(App)
  // Configure store
  getServerConfig(app).then(async (config) => {
    injectResponsiveStorage(app, config)
    setupStore(app)
    app.use(ElementPlus)
    app.use(router).use(MotionPlugin).use(useIcon).use(usI18n).use(useVxeTable).use(useFormCreate)
    await router.isReady()
    app.mount('#app')
  })
}

bootstrap()
