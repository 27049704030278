import Layout from '@/layout/index.vue'
import { $t } from '@/plugins/i18n'

const customerRouter = {
  path: '/customer',
  name: 'customer',
  component: Layout,
  redirect: '/customer/staff',
  meta: {
    icon: 'ShoppingTrolley',
    title: $t('menus.hscustomer-manage'),
    i18n: true,
    rank: 90,
  },
  children: [
    {
      path: '/customer/staff',
      name: 'customerStaff',
      component: () => import('@/views/customer/staff.vue'),
      meta: {
        title: $t('menus.hscustomer-staff'),
        i18n: true,
        icon: 'Sell',
        rank: 901,
        isHidden: false,
        keepAlive: true,
      },
    },
  ],
}

export default customerRouter
