// 响应式storage
import { App } from 'vue'
import Storage from 'responsive-storage'
import { routerArrays } from '@/layout/types'

export const injectResponsiveStorage = (app: App, config: ServerConfigs) => {
  const configObj = Object.assign(
    {
      // 国际化 默认中文zh
      locale: Storage.getData('locale') ?? {
        locale: config.Locale ?? 'zh',
      },
      // layout模式以及主题
      layout: Storage.getData('layout') ?? {
        layout: config.Layout ?? 'vertical',
        theme: config.Theme ?? 'default',
        darkMode: config.DarkMode ?? false,
        sidebarStatus: config.SidebarStatus ?? true,
        epThemeColor: config.EpThemeColor ?? '#409EFF',
      },
      configure: Storage.getData('configure') ?? {
        grey: config.Grey ?? false,
        weak: config.Weak ?? false,
        hideTabs: config.HideTabs ?? false,
        showLogo: config.ShowLogo ?? true,
        showModel: config.ShowModel ?? 'smart',
        multiTagsCache: config.MultiTagsCache ?? false,
      },
    },
    config.MultiTagsCache
      ? {
          // 默认显示首页tag
          tags: Storage.getData('tags') ?? [...routerArrays],
        }
      : {}
  )

  app.use(Storage, {
    memory: configObj,
  })
}
