import Layout from '@/layout/index.vue'
import { $t } from '@/plugins/i18n'

const mallRouter = {
  path: '/mall',
  name: 'mall',
  component: Layout,
  redirect: '/mall/index',
  meta: {
    icon: 'Handbag',
    title: $t('menus.hsmall-management'),
    i18n: true,
    rank: 4,
  },
  children: [
    {
      path: '/mall/index',
      name: 'mallManage',
      component: () => import('@/views/mall/index.vue'),
      meta: {
        title: $t('menus.hsmall-manage'),
        i18n: true,
        icon: 'HomeFilled',
        rank: 41,
        isHidden: false,
        keepAlive: true,
      },
    },
    {
      path: '/mall/home',
      name: 'mallHome',
      component: () => import('@/views/mall/home.vue'),
      meta: {
        title: $t('menus.hsmall-home'),
        i18n: true,
        icon: 'MagicStick',
        rank: 42,
        isHidden: false,
        keepAlive: false,
      },
    },
    {
      path: '/mall/template',
      name: 'mallTemplate',
      component: () => import('@/views/mall/template.vue'),
      meta: {
        title: $t('menus.hsmall-template'),
        i18n: true,
        icon: 'MessageBox',
        rank: 43,
        isHidden: false,
        keepAlive: true,
      },
    },
    {
      path: '/mall/decoration',
      name: 'decoration',
      component: () => import('@/views/mall/decoration.vue'),
      meta: {
        title: $t('menus.hsmall-decoration'),
        i18n: true,
        icon: 'MagicStick',
        rank: 44,
        isHidden: true,
        keepAlive: false,
      },
    },
  ],
}

export default mallRouter
