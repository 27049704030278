import { storageLocal } from '@/utils/storage'

const TokenKey = 'authorized-token'

// 获取token
export function getToken() {
  return storageLocal.getItem(TokenKey) || ''
}

// 设置token以及过期时间（cookies）
export function setToken(accessToken: string) {
  storageLocal.setItem(TokenKey, accessToken)
}

// 删除token
export function removeToken() {
  storageLocal.removeItem(TokenKey)
}

const RouterCodeKey = 'router_code'

export function getRouterCode() {
  return localStorage.getItem(RouterCodeKey) || ''
}

export function setRouterCode(code: string) {
  localStorage.setItem(RouterCodeKey, code)
}

export function removeRouterCode() {
  localStorage.removeItem(RouterCodeKey)
}
