<script setup lang="ts">
import Logo from './logo.vue'
import { emitter } from '@/utils/mitt'
import SidebarItem from './sidebarItem.vue'
import { storageLocal } from '@/utils/storage'
import { RouteRecordRaw, useRoute, useRouter } from 'vue-router'
import { computed, ref, onBeforeMount, onBeforeUnmount } from 'vue'
import { useAppStoreHook } from '@/store/modules/app'
import { usePermissionStoreHook } from '@/store/modules/permission'
import { childrenType } from '@/layout/types'

const route = useRoute()
const pureApp = useAppStoreHook()
const router = useRouter().options.routes
const showLogo = ref(storageLocal.getItem('rs-configure')?.showLogo ?? true)
const isCollapse = computed(() => {
  return !pureApp.getSidebarStatus
})
const activeMenu = computed((): string => {
  const { meta, path } = route
  if (meta.activeMenu) {
    // @ts-ignore
    return meta.activeMenu
  }
  return path
})

onBeforeMount(() => {
  emitter.on('logoChange', (key) => {
    showLogo.value = key
  })
})

onBeforeUnmount(() => {
  // 解绑`logoChange`公共事件，防止多次触发
  emitter.off('logoChange')
})
</script>

<template>
  <div :class="['sidebar-container', showLogo ? 'has-logo' : '']">
    <Logo v-if="showLogo" :collapse="isCollapse" />
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        :default-active="activeMenu"
        :collapse="isCollapse"
        unique-opened
        router
        :collapse-transition="false"
        mode="vertical"
        class="outer-most"
      >
        <sidebar-item
          v-for="menu in usePermissionStoreHook().wholeMenus"
          :key="menu.path"
          :item="menu"
          class="outer-most"
          :base-path="menu.path"
        />
      </el-menu>
    </el-scrollbar>
  </div>
</template>
