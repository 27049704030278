//plugins/i18n.ts
import { App } from 'vue'
import { createI18n } from 'vue-i18n'
import messages from '@intlify/unplugin-vue-i18n/messages'
import { storageLocal } from '@/utils/storage'

/**
 * 国际化转换工具函数
 * @param message message
 * @returns message
 */
export function transformI18n(message?: string) {
  if (!message) {
    return ''
  }
  if (i18n.global.te(message)) {
    //@ts-ignore
    return i18n.global.t(message)
  } else {
    return message
  }
}

// 此函数只是配合i18n Ally插件来进行国际化智能提示，并无实际意义（只对提示起作用），如果不需要国际化可删除
export const $t = (key: string) => key

export const i18n = createI18n({
  legacy: false,
  locale: storageLocal.getItem('rs-locale')?.locale ?? 'zh',
  globalInjection: true,
  fallbackLocale: 'zh',
  messages: {
    zh: messages,
  },
})

const install = (app: App) => {
  app.use(i18n)
}

export default install
