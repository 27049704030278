<template>
  <div>
    <el-input v-model="path" placeholder="请选择跳转地址">
      <template #append>
        <el-button @click="dialogVisible = true">选择</el-button>
      </template>
    </el-input>
    <el-dialog
      v-model="dialogVisible"
      title="选择链接"
      :before-close="handleClose"
      width="700px"
      align-center
      append-to-body
    >
      <page-select @change="handleChange"></page-select>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="onConfirm"> 确定 </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script setup>
import PageSelect from '@/components/PageSelect/index.vue'
const path = ref('')
const dialogVisible = ref(false)

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
})
const emit = defineEmits(['update:modelValue', 'change'])
const selectPath = ref('')

onMounted(() => {
  path.value = props.modelValue
})

watch(path, () => {
  emit('update:modelValue', path.value)
  emit('change', path.value)
})
const handleClose = () => {
  dialogVisible.value = false
}
const onConfirm = () => {
  path.value = selectPath.value
  handleClose()
}
const handleChange = (path) => {
  selectPath.value = path
}
</script>
<style lang="scss" scoped></style>
