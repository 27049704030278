<template>
  <div class="custom-color-picker">
    <el-input v-model="color" placeholder="Please input">
      <template #prepend><el-color-picker v-model="color" /></template>
    </el-input>
  </div>
</template>
<script setup>
const color = ref('')

const props = defineProps({
  modelValue: '',
})
const emit = defineEmits()

onMounted(() => {
  color.value = props.modelValue
})

watch(color, () => {
  emit('update:modelValue', color.value)
  emit('change', color.value)
})
</script>
<style lang="scss" scoped>
.custom-color-picker {
  ::v-deep(.el-input-group__prepend) {
    background-color: var(--el-fill-color-blank);
    padding: 0;
  }
  ::v-deep(.el-color-picker__trigger) {
    border: none;
    padding: 1px;
    .el-color-picker__color {
      border: none;
      .el-color-picker__color-inner {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }
  }
}
</style>
