<script setup lang="ts">
import { computed, unref, watch, nextTick, onMounted, getCurrentInstance } from 'vue'
import { useI18n } from 'vue-i18n'
import { emitter } from '@/utils/mitt'
import Notice from '../notice/index.vue'
import { templateRef } from '@vueuse/core'
import SidebarItem from './sidebarItem.vue'
import screenfull from '../screenfull/index.vue'
import { RouteRecordRaw, useRoute, useRouter } from 'vue-router'
import { storageSession } from '@/utils/storage'
import { deviceDetection } from '@/utils/deviceDetection'
import { usePermissionStoreHook } from '@/store/modules/permission'
import { useUserStoreHook } from '@/store/modules/user'
import globalization from '@/assets/icons/globalization.svg?component'

const userStore = useUserStoreHook()
const instance = getCurrentInstance()?.appContext.config.globalProperties.$storage

const title = getCurrentInstance()?.appContext.config.globalProperties.$config?.Title

const menuRef = templateRef<ElRef | null>('menu', null)
const route = useRoute()
const router = useRouter()
const routers = useRouter().options.routes
let username = storageSession.getItem('info')?.username
const { locale, t } = useI18n()

const getDropdownItemStyle = computed(() => {
  return (t: string) => {
    return {
      background: locale.value === t ? '#1b2a47' : '',
      color: locale.value === t ? '#f4f4f5' : '#000',
    }
  }
})

watch(
  () => locale.value,
  () => {
    //@ts-ignore
    // 动态title
    document.title = t(unref(route.meta.title))
  }
)

// 退出登录
const logout = (): void => {
  userStore.logout()
}

function onPanel() {
  emitter.emit('openPanel', '')
}

const activeMenu = computed((): string => {
  const { meta, path } = route
  if (meta.activeMenu) {
    // @ts-ignore
    return meta.activeMenu
  }
  return path
})

function backHome() {
  router.push('/dashboard')
}

function handleResize() {
  // @ts-ignore
  menuRef.value.handleResize()
}

// 简体中文
function translationCh() {
  instance.locale = { locale: 'zh' }
  locale.value = 'zh'
  handleResize()
}

// English
function translationEn() {
  instance.locale = { locale: 'en' }
  locale.value = 'en'
  handleResize()
}

onMounted(() => {
  nextTick(() => {
    handleResize()
  })
})
</script>

<template>
  <div class="horizontal-header">
    <div class="horizontal-header-left" @click="backHome">
      <img src="@/assets/logo.png" alt="" style="width: 35px; height: 35px" />
      <h4>{{ title }}</h4>
    </div>
    <el-menu
      ref="menu"
      :default-active="activeMenu"
      unique-opened
      router
      class="horizontal-header-menu"
      mode="horizontal"
    >
      <sidebar-item
        v-for="r in usePermissionStoreHook().wholeMenus"
        :key="r.path"
        :item="r"
        :base-path="r.path"
      />
    </el-menu>
    <div class="horizontal-header-right">
      <!-- 通知 -->
      <Notice id="header-notice" />
      <!-- 全屏 -->
      <screenfull v-show="!deviceDetection()" id="header-screenfull" />
      <!-- 国际化 -->

      <!-- 退出登陆 -->
      <el-dropdown trigger="click">
        <span class="el-dropdown-link">
          <img src="@/assets/avatars.png" />
          <p class="ml-2">{{ username }}</p>
        </span>
        <template #dropdown>
          <el-dropdown-menu class="logout">
            <el-dropdown-item @click="logout">
              <icon icon="ri:logout-box-r-line" class="m-5px" />
              {{ $t('buttons.hsLoginOut') }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <icon
        class="icon-setting"
        :title="$t('buttons.hssystemSet')"
        icon="ri:settings-line"
        :size="18"
        @click="onPanel"
      >
      </icon>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.translation {
  ::v-deep(.el-dropdown-menu__item) {
    padding: 5px 40px;
  }

  .check-zh {
    position: absolute;
    left: 20px;
  }

  .check-en {
    position: absolute;
    left: 20px;
  }
}

.logout {
  max-width: 120px;

  ::v-deep(.el-dropdown-menu__item) {
    min-width: 100%;
    display: inline-flex;
    flex-wrap: wrap;
  }
}
</style>
