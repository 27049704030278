import Layout from '@/layout/index.vue'
import { $t } from '@/plugins/i18n'
import { createCustomComponent } from '../utils'

const goodsRouter = {
  path: '/order',
  name: 'order',
  component: Layout,
  redirect: '/order/index',
  meta: {
    icon: 'ShoppingTrolley',
    title: $t('menus.hsorder-manage'),
    i18n: true,
    rank: 80,
  },
  children: [
    {
      path: '/order/index',
      name: 'orderManage',
      component: () => import('@/views/order/index.vue'),
      meta: {
        title: $t('menus.hsorder-sales'),
        i18n: true,
        icon: 'Sell',
        rank: 801,
        isHidden: false,
        keepAlive: true,
      },
    },
    {
      path: '/order/card',
      name: 'orderCardManage',
      component: () => import('@/views/order/card.vue'),
      meta: {
        title: $t('menus.hsorder-recharge'),
        i18n: true,
        icon: 'Wallet',
        rank: 802,
        isHidden: false,
        keepAlive: true,
      },
    },
  ],
}

export default goodsRouter
