<template>
  <div class="custom-image-radio">
    <div
      v-for="item in list"
      :key="item.type"
      class="image-container"
      :class="{ active: item.type === current }"
    >
      <img :src="item.src" alt="" @click="current = item.type" />
    </div>
  </div>
</template>
<script setup>
const current = ref('')

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  list: {
    type: Array,
    default: () => [],
  },
})
const emit = defineEmits(['update:modelValue', 'change'])

onMounted(() => {
  current.value = props.modelValue
})

watch(current, () => {
  emit('update:modelValue', current.value)
  emit('change', current.value)
})
</script>
<style lang="scss" scoped>
.image-container {
  margin-bottom: 10px;

  img {
    border: 1px solid #f3f3f3;
  }
  &.active {
    img {
      border: 2px solid var(--el-color-primary);
    }
  }
}
</style>
