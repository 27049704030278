import { defineStore } from 'pinia'
import { store } from '@/store'
import { storageLocal } from '@/utils/storage'
import { multiType, positionType } from './types'
import { routerArrays } from '@/layout/types'
import { isBoolean, isUrl } from '@/utils/is'

export const useMultiTagsStore = defineStore({
  id: 'pure-multiTags',
  state: () => ({
    // 存储标签页信息（路由信息）
    multiTags: storageLocal.getItem('rs-configure').multiTagsCache
      ? (storageLocal.getItem('rs-tags') as multiType[])
      : ([...routerArrays] as multiType[]),
    multiTagsCache: storageLocal.getItem('rs-configure').multiTagsCache,
  }),
  getters: {
    getMultiTagsCache(): string {
      return this.multiTagsCache
    },
  },
  actions: {
    multiTagsCacheChange(multiTagsCache: boolean) {
      this.multiTagsCache = multiTagsCache
      if (multiTagsCache) {
        storageLocal.setItem('rs-tags', this.multiTags)
      } else {
        storageLocal.removeItem('rs-tags')
      }
    },
    tagsCache(multiTags: multiType[]) {
      if (this.getMultiTagsCache) {
        storageLocal.setItem('rs-tags', multiTags)
        this.multiTags = multiTags
      }
    },
    handleTags(mode: 'equal' | 'push' | 'splice' | 'slice'): Fn {
      switch (mode) {
        case 'equal':
          return (value: multiType[]) => {
            this.multiTags = value
            this.tagsCache(this.multiTags)
          }
        case 'push':
          return (value: multiType) => {
            const tagVal = value
            // 不添加到标签页
            if (tagVal?.meta?.hiddenTag) return
            // 如果是外链无需添加信息到标签页
            if (isUrl(tagVal?.name)) return
            // 如果title为空拒绝添加空信息到标签页
            if (tagVal?.meta?.title.length === 0) return
            const tagPath = tagVal.path
            // 判断tag是否已存在
            const targetIndex = this.multiTags.findIndex((tag) => {
              return tag.path === tagPath
            })

            // 判断tag中的fullPath键值是否相等
            const tagFullPathHasExit = this.multiTags.some((tag) => {
              return tag.fullPath === tagVal.fullPath
            })

            if (targetIndex > -1 && tagFullPathHasExit) {
              // 如果tag已存在，更新tag信息 且完全匹配，直接返回
              return
            } else if (targetIndex > -1) {
              // 如果tag存在，单fullPath不同
              this.multiTags[targetIndex] = tagVal
              this.tagsCache(this.multiTags)
              return this.multiTags
            }

            // 动态路由可打开的最大数量
            const dynamicLevel = tagVal?.meta?.dynamicLevel ?? -1
            if (dynamicLevel > 0) {
              if (this.multiTags.filter((e) => e?.path === tagPath).length >= dynamicLevel) {
                // 如果当前已打开的动态路由数大于dynamicLevel，替换第一个动态路由标签
                const index = this.multiTags.findIndex((item) => item?.path === tagPath)
                index !== -1 && this.multiTags.splice(index, 1)
              }
            }
            this.multiTags.push(value)
            this.tagsCache(this.multiTags)
            return this.multiTags
          }
        case 'splice':
          return (value: string, position: positionType) => {
            if (!position) {
              const index = this.multiTags.findIndex((v) => v.path === value)
              if (index === -1) return
              this.multiTags.splice(index, 1)
            } else {
              this.multiTags.splice(position?.startIndex ?? 0, position?.length)
            }
            this.tagsCache(this.multiTags)
            return this.multiTags
          }
        case 'slice':
          return () => {
            return this.multiTags.slice(-1)
          }
        default:
          // eslint-disable-next-line no-case-declarations
          const handlerError: never = mode
          return handlerError
      }
    },
  },
})

export function useMultiTagsStoreHook() {
  return useMultiTagsStore(store)
}
