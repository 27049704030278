import VXETable from 'vxe-table'
import tableExport from 'vxe-table-plugin-export-xlsx'
import XEUtils from 'xe-utils'
import { regFenToYuan } from '@/utils/money'
import { App } from 'vue'

VXETable.use(tableExport)
VXETable.setup({
  size: 'small',
  pager: {
    size: null,
    autoHidden: false,
    perfect: false,
    pageSize: 10,
    pagerCount: 7,
    pageSizes: [10, 15, 20, 50, 100],
    layouts: [
      'PrevJump',
      'PrevPage',
      'Jump',
      'PageCount',
      'NextPage',
      'NextJump',
      'Sizes',
      'Total',
    ],
  },
})
VXETable.formats.mixin({
  // 格式化日期，默认 yyyy-MM-dd
  formatDate({ cellValue }, format) {
    return XEUtils.toDateString(cellValue, format || 'yyyy-MM-dd')
  },
  formatMoney({ cellValue }) {
    return regFenToYuan(cellValue) + ''
  },
})
const install = (app: App) => {
  app.use(VXETable)
}

export default install
