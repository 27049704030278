<template>
  <section
    :class="[props.fixedHeader ? 'app-main' : 'app-main-nofixed-header']"
    :style="getSectionStyle"
  >
    <router-view>
      <template #default="{ Component, route: routeScope }">
        <el-scrollbar v-if="props.fixedHeader">
          <el-backtop title="回到顶部" target=".app-main .el-scrollbar__wrap">
            <backTop />
          </el-backtop>
          <transitionMain :route="routeScope">
            <keep-alive :include="isKeepAlive ? usePermissionStoreHook().cachePageList : []">
              <component :is="Component" :key="routeScope.name" class="main-content" />
            </keep-alive>
          </transitionMain>
        </el-scrollbar>
        <div v-else>
          <transitionMain :route="routeScope">
            <keep-alive :include="isKeepAlive ? usePermissionStoreHook().cachePageList : []">
              <component :is="Component" :key="routeScope.name" class="main-content" />
            </keep-alive>
          </transitionMain>
        </div>
      </template>
    </router-view>
  </section>
</template>
<script setup lang="ts">
import { h, ref, computed, Transition, defineComponent, getCurrentInstance, onMounted } from 'vue'
import { RouteLocationNormalizedLoaded, RouterView } from 'vue-router'
import backTop from '@/assets/icons/back-top.svg?component'
import { usePermissionStoreHook } from '@/store/modules/permission'

const props = defineProps({
  fixedHeader: Boolean,
})
const isKeepAlive = ref(getCurrentInstance()?.appContext.config.globalProperties.$config?.KeepAlive)
const instance = getCurrentInstance()?.appContext.app.config.globalProperties.$storage

const transitions = computed(() => {
  return (route: any) => {
    return route.meta.transition
  }
})

const hideTabs = computed(() => {
  return instance?.configure.hideTabs
})

const layout = computed(() => {
  return instance?.layout.layout === 'vertical'
})

const getSectionStyle = computed(() => {
  return [
    hideTabs.value && layout ? 'padding-top: 48px;' : '',
    !hideTabs.value && layout ? 'padding-top: 85px;' : '',
    hideTabs.value && !layout.value ? 'padding-top: 48px' : '',
    !hideTabs.value && !layout.value ? 'padding-top: 85px;' : '',
    props.fixedHeader ? '' : 'padding-top: 0;',
  ]
})

const transitionMain = defineComponent({
  props: {
    route: {
      type: Object as PropType<RouteLocationNormalizedLoaded>,
      required: true,
    },
  },
  render() {
    return h(
      Transition,
      {
        name:
          transitions.value(this.route) && (this.route?.meta?.transition as any).enterTransition
            ? 'pure-classes-transition'
            : (transitions.value(this.route) && (this.route?.meta?.transition as any).name) ||
              'fade-transform',
        enterActiveClass:
          transitions.value(this.route) &&
          `animate__animated ${(this.route?.meta?.transition as any).enterTransition}`,
        leaveActiveClass:
          transitions.value(this.route) &&
          `animate__animated ${(this.route?.meta?.transition as any).leaveTransition}`,
        // mode: 'out-in',
        appear: true,
      },
      {
        default: () => [this.$slots.default?.()],
      }
    )
  },
})
const warningsRef = ref()

onMounted(() => {})
</script>

<style scoped>
.app-main {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow-x: hidden;
}

.app-main-nofixed-header {
  width: 100%;
  min-height: 100vh;
  position: relative;
}
.warnings {
  position: fixed;
  top: calc(50vh - 32px);
  z-index: 2022;
  left: calc(100vw - 63px);
}
</style>
