<template>
  <el-row :gutter="20">
    <el-col :span="8" v-for="(item, index) in list" :key="index">
      <div class="image-container" :class="{ active: index === current }">
        <img :src="item" alt="" @click="current = index" /></div
    ></el-col>
  </el-row>
</template>
<script setup>
const current = ref('')

const props = defineProps({
  modelValue: '',
  list: [],
})
const emit = defineEmits()

onMounted(() => {
  current.value = props.modelValue
})

watch(current, () => {
  emit('update:modelValue', current.value)
  emit('change', current.value)
})
</script>
<style lang="scss" scoped>
.image-container {
  margin-bottom: 10px;

  img {
    border: 1px solid #f3f3f3;
  }
  &.active {
    img {
      border: 2px solid var(--el-color-primary);
    }
  }
}
</style>
